<template>
  <div class="bill-document p-4">
    <div class="col-12 bill-document-inners">
        <div class="row hearder"></div>
        <div class="row py-2">
          <div class="col-8">
            <div class="row">
              <div class="logo d-inline-block col-2 px-0 pl-2">
                <div class="logo-inner">
                  <img alt="WYA Yoga" fluid class="d-block" src="../assets/images/logo/logo.png">
                </div>
              </div>
              <div class="seller-info col-10 pl-2">
                <h3 class="title font-weight-bold text-uppercase mb-1">World Yoga Alliance®</h3>
                <p class="address mb-0">Shanti Marg, Haripur Kalan, Haridwar, Raiwala, Dehradun, Uttarakhand 249205, India</p>
                <p class="phone mb-0">+91 9258-780-768</p>
                <p class="website mb-0">www.wyayoga.org</p>
              </div>
            </div>
            <div class="row mt-2 py-2">
               <div class="col-12 col-md-11 bill-to">
                <h6 class="font-weight-bold text-uppercase">Bill to:</h6>
                <div class="box border">
                  <p class="name mb-1">{{ invoice.first_name }} {{ invoice.last_name }}</p>
                  <p class="address">{{ invoice.company_name }} {{ invoice.address }} {{ invoice.region_name }} {{ invoice.country_name }}  {{ invoice.post_code }}</p>
                  <p class="phone mb-1">Phone: {{ invoice.phone_number }}</p>
                  <p class="email">{{ invoice.email }}</p>
                </div>
               </div>
            </div>
          </div>
          <div class="col-4 pl-1">
            <div v-if="invoice" class="row">
              <div class="col-12 text-right pr-2">
                 <h3 class="document-title font-weight-bold text-uppercase mb-1">{{ documentTitle }}</h3>
                 <p v-if="invoice.status === 2" class="receipt-date mb-0">{{ invoice.receipt_date }}</p>
                 <p class="invoice-date mb-0">Date: {{ invoice.invoice_date }}</p>
                 <p class="invoice-number mb-0">Invoice no: {{ invoice.ref_invoice }}</p>
                 <p v-if="invoice.status === 2" class="receipt-number mb-0">Receipt no: {{ invoice.ref_invoice }}</p>
               </div>
             </div>
          </div>
        </div>
        <div class="row py-2">
          <div class="col-12 orders-detail">
            <h6 class="font-weight-bold text-uppercase">Description:</h6>
            <div class="">
              <b-table bordered hover :items="items" :fields="fields">
                <!-- A total_amount  Hearder th column -->
                <template #head(total_amount)="row">
                  <span class="d-block text-right">{{ row.label }}</span>
                </template>
                <!-- A unit_price Hearder th column -->
                <template #head(price)="row">
                  <span class="d-block text-right">{{ row.label }}</span>
                </template>
                <!-- A quantity Hearder th column -->
                <template #head(quantity)="row">
                  <span class="d-block text-center">{{ row.label }}</span>
                </template>

                <!-- A unit_price column -->
                <template #cell(price)="row">
                  <span class="d-block text-right">{{ getAmountByCurrency(row.item, 'price', invoice.paid_currency) | numeral('0,0.00') }}</span>
                </template>
                <!-- A quantity column -->
                <template #cell(quantity)="row">
                  <span class="d-block text-center">{{ row.item.quantity | numeral('0,0') }}</span>
                </template>
                <!-- A Total amount column -->
                <template #cell(total)="row">
                  <span class="d-block text-right">{{ getAmountByCurrency(row.item, 'total', invoice.paid_currency) | numeral('0,0.00') }}</span>
                </template>

                <template v-slot:custom-foot="">
                  <b-tr class="text-right font-weight-500">
                    <b-td colspan="4" class="text-uppercase border-right">Subtotal:</b-td>
                    <b-td class="border-right border-bottom"><span class="pr-2">{{ getAmountByCurrency(invoice, 'sub_total', invoice.paid_currency) | numeral('0,0.00') }}</span><span class="text-uppercase"> {{ invoice.paid_currency }} </span></b-td>
                  </b-tr>
                  <b-tr class="text-right font-weight-500">
                    <b-td colspan="4" class="text-uppercase border-right">Discount:</b-td>
                    <b-td class="border-right border-bottom"><span class="pr-2">{{ getAmountByCurrency(invoice, 'discount_amount', invoice.paid_currency) | numeral('0,0.00') }}</span><span class="text-uppercase">{{ invoice.paid_currency }}</span></b-td>
                  </b-tr>
                  <b-tr class="text-right font-weight-500">
                    <b-td colspan="4" class="text-uppercase border-right">Total:</b-td>
                    <b-td class="border-right border-bottom"><span class="pr-2">{{ getAmountByCurrency(invoice, 'total', invoice.paid_currency) | numeral('0,0.00') }}</span><span class="text-uppercase">{{ invoice.paid_currency }}</span></b-td>
                  </b-tr>
                  <b-tr class="text-right font-weight-500">
                    <b-td colspan="4" class="text-uppercase border-right">Include VAT/GST:</b-td>
                    <b-td class="border-right border-bottom"><span class="pr-2">{{ getAmountByCurrency(invoice, 'vat', invoice.paid_currency) | numeral('0,0.00') }}</span><span class="text-uppercase">{{ invoice.paid_currency }}</span></b-td>
                  </b-tr>
                  <b-tr class="font-weight-bold">
                    <b-td colspan="4" class="text-right text-uppercase border-right">Grand Total:</b-td>
                    <b-td class="text-right border-right border-bottom "><span class="pr-2">{{ getAmountByCurrency(invoice, 'grand_total', invoice.paid_currency) | numeral('0,0.00') }}</span><span class="text-uppercase">{{ invoice.paid_currency }}</span></b-td>
                  </b-tr>
                </template>
               </b-table>
            </div>
          </div>
        </div>
        <div class="row pb-3">
          <div class="col-12 col-md-7 payment-info">
            <div class="row px-2">
              <div class="col-12 option">
                <h6 class="font-weight-bold text-uppercase"><u>Payment option:</u></h6>
                <b-form-group
                  label=""
                  v-slot="{ ariaDescribedby }"
                >
                  <b-form-checkbox-group
                    v-model="paymentSelected"
                    :options="paymentOptions"
                    :aria-describedby="ariaDescribedby"
                    name="flavour-1a"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <div class="col-12 status">
                <span class="h6 font-weight-bold text-uppercase inline-block pr-3"><u>Paid Currency:</u></span>
                <span class="text-uppercase inline-block"> {{ currencyName(invoice.paid_currency) }} ({{ invoice.paid_currency }})</span>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-5 text-center">
            <div class="signature-box col-12 pb-3">
            <hr class="mb-1" />
            <img v-if="invoice.status == 2" alt="WYA Yoga" height="70" style="position: absolute;margin-top:-65px;" src="../assets/images/invoice/signature.png">
            <img v-if="invoice.status == 2" alt="WYA Yoga" height="65" width="65" style="opacity:0.3;position: absolute;margin-top:-75px; margin-left:40px" src="../assets/images/invoice/wya_org_stamp.png">
            <label class="font-weight-500">Signature</label>
            </div>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-12 note text-center">
            <p class="mb-0">Should you have any enquiries concerning this receipt, please contact us.</p>
            <p>Email: contact@wyayoga.org</p>
          </div>
        </div>
        <div class="row footer">
          <div class="col-12 footer-detail text-center small pt-3 pb-2">
            <p class="mb-1"><b>World Yoga Alliance® INDIA</b></p>
            <p class="mb-1">Yoga Trusted Organization Recognized and Registered with Ministry of Human Resource Development (NCT) New Delhi-110042 (Government of India) Est. 17th November 2011, Official Registration ID Number: 5509</p>
          </div>
           <div class="col-12 footer-bg"></div>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'Invoice',
  props: {
    documentTitle: {
      type: String,
      default: 'Invoice'
    },
    invoiceData: {
      type: Object
    },
    orderData: {
      type: Object
    },
    paymentTransactionData: {
      type: Object
    }
  },
  data () {
    return {
      invoice: this.invoiceData,
      fields: [{ key: 'item_code', label: 'Code' }, { key: 'name', label: 'Item' }, { key: 'price', label: 'Unit Price' }, { key: 'quantity', label: 'Quantity' }, { key: 'total', label: 'Total Amount' }],
      items: this.orderData.order_item_set,
      paymentSelected: [], // Must be an array reference!
      paymentOptions: [
        { text: 'Credit Card', value: 1, disabled: true },
        { text: 'Money Transfer', value: 2, disabled: true },
        { text: 'Cash', value: 3, disabled: true },
        { text: 'Paypal', value: 4, disabled: true },
        { text: 'Western Union / MoneyGram', value: 5, disabled: true },
        { text: 'TransferWise', value: 6, disabled: true },
        { text: 'Other', value: 7, disabled: true }
      ],
      currencies: {
        USD: 'United States Dollar',
        THB: 'Thai Baht',
        INR: 'Indian Rupees',
        EUR: 'Euro'
      }
    }
  },
  mounted () {
    this.paymentSelected = (this.paymentTransactionData ? [this.paymentTransactionData.payment_method] : [])
  },
  methods: {
    currencyName (currency) {
      return this.currencies[currency.toUpperCase()]
    },
    getAmountByCurrency (item, field, currency) {
      const fieldName = field + '_' + currency
      return item[fieldName]
    }
  }
}
</script>

<style scoped>
</style>
